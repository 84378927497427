$('body').on('submit', '#ajaxFeedbackForm', function (e) {

    e.preventDefault();

    $.ajax({
        type: $(this).attr('method'),
        url: $(this).attr('action'),
        data: $(this).serialize()
    })
        .done(function (data) {
            $('#feedback-popup').html(data);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            $('#reserveForm').parent().html(jqXHR.responseJSON.form);
        });
});